<template>
  <div>
    <h1>404</h1>
    <h2>Seite nicht gefunden</h2>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style scoped></style>
